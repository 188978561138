<script>
import brandsService from '@/services/brands.service'
import lazyType from '@/utils/lazy-type'

export default {
  name: 'Brands',
	data() {
		return {
      items: [],
      limit: 20,
      offset: 1,
      totalItems: 0,
      selectedBrandType: null,
      keyword: "",
      lazyTypeDelay: 200,
      headerFields: [
        "__slot:checkboxes",
        {
          name: "published",
          label: this.$t('published'),
          customElement: "published",
        },
        {
          name: "photo",
          label: this.$tc('photo'),
          customElement: "photo",
        },
        {
          name: "title",
          label: this.$t('title'),
          customElement: "title",
        },
        {
          name: "alias",
          label: this.$tc('alias'),
        },
        "__slot:actions:edit",
        "__slot:actions:delete"
      ],
		}
  },
	mounted() {
    this._get()
	},
	methods: {
    lazyType,
    _get(resetOffset = true) {
      if(resetOffset) this.offset = 1
      brandsService.getAll({ limit: this.limit, offset: this.offset, keyword: this.keyword })
      .then(response => {
          this.items = response.data
          this.totalItems = Number(response.headers['x-items-count']) || 0
				})
    },
    updatePagination(offset) {
      this.offset = offset
      this._get(false)
    },
    filterBySearch() {
      this._get()
    },
    remove(id) {
      brandsService.remove({ id })
        .then(success => {
          this.$notify({
              message: this.$t('m.itemSuccessfullyDeleted', { item: this.$tc('brand') }),
              icon: "far fa-bell",
              horizontalAlign: 'right',
              verticalAlign: 'top',
              type: 'success',
            })
          const index = this.items.findIndex(obj => obj.id === id)
          this.items.splice(index, 1)
        })
    }
	}
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="form-group col-12 text-right">
          <router-link
            :to="{ name: 'singleBrand', params: { id: 'new' } }"
          >
            <BaseButton 
              type="green"
              gradient
            >
              {{ $t('addNewItem', { item: $tc('brand') }) }}
            </BaseButton>
          </router-link> 
        </div>
      </div>
      <div class="row justify-content-between align-items-end">
        <div class="col-md-3">
          <BaseInput
            v-model="keyword"
            :placeholder="$t('search')"
            class="search"
            @keyup.prevent.stop="lazyType(filterBySearch, lazyTypeDelay)"
          />
        </div>
      </div>
    </div>  
    <div class="card-body">
      <BaseListTable
        :items="items"
        :total-items="totalItems"
        :limit="limit"
        :offset="offset"
        :header-fields="headerFields"
        type="brands"
        has-pagination
        @updatePagination="updatePagination"
        @delete="remove"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.search
  margin-bottom: $base-spacing / 4
</style>
